import React, { Component } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const getTax = (nodes, tax) => {
  // assemble an array of taxonomy objects from all posts
  let taxItems = []
  if (tax === "domains") {
    nodes.map(node => {
      return node.policyDomains.map(domain => taxItems.push(domain))
    })
  } else if (tax === "methods") {
    nodes.map(node => {
      return node.methods.map(method => taxItems.push(method))
    })
  }
  // reduce to an array of objects in which each object only appears once
  let uniqueTaxItems = [
    ...new Map(taxItems.map(node => [`${node.contentful_id}:${node.title}`, node])).values()
  ];
  return uniqueTaxItems
}

class ProjectFilters extends Component {
  state = {
    items: this.props.items.allContentfulProject.nodes,
    projects: this.props.items.allContentfulProject.nodes,
    domains: getTax(this.props.items.allContentfulProject.nodes, "domains"),
    methods: getTax(this.props.items.allContentfulProject.nodes, "methods"),
    domainValue: "All",
    methodValue: "All",
  }

  handleTaxChange = (event) => {
    const { items, methodValue, domainValue } = this.state
    let taxChoice = event.target.parentElement.dataset.value
    let allTaxDropdownItems = Array.from(document.querySelectorAll(`.dropdown-menu[data-value="${taxChoice}"] .dropdown-item`))
    let termChoice = event.target.dataset.value

    if (taxChoice === "Policy Domain") {
      allTaxDropdownItems.forEach(item => {
        // remove active class from all buttons in this list
        item.classList.remove("active")
      })
      // add active class to clicked item
      event.target.classList.add("active")
      // set state value to user selection
      this.setState({
        domainValue: termChoice
      })

      if (termChoice === "All") {
        if (methodValue === "All") {
          // if both are set to "All," reset the posts
          this.setState({
            projects: [...items],
            domains: getTax([...items], "domains"),
            methods: getTax([...items], "methods"),
          })
        } else {
          // if domain is "All" but method is not "All," filter by method choice
          const posts = items.filter(node => {
            return node.methods.some(method => method.title === methodValue)
          })
          this.setState({
            projects: posts,
            domains: getTax(posts, "domains"),
            methods: getTax(posts, "methods")
          })
        }
      } else {
        if (methodValue !== "All") {
          // if both are not set to "All," filter by both
          const posts = items.filter(node => {
            return node.policyDomains.some(domain => domain.title === termChoice) &&
                   node.methods.some(method => method.title === methodValue)
          })
          this.setState({
            projects: posts,
            domains: getTax(posts, "domains"),
            methods: getTax(posts, "methods")
          })
        } else {
          // if domain is not "All," but method is, filter by domain choice
          const posts = items.filter(node => {
            return node.policyDomains.some(domain => domain.title === termChoice)
          })
          this.setState({
            projects: posts,
            methods: getTax(posts, "methods")
          })
        }
      }
    }
    
    if (taxChoice === "Method") {
      allTaxDropdownItems.forEach(item => {
        // remove active class from all buttons in this list
        item.classList.remove("active")
      })
      // add active class to clicked item
      event.target.classList.add("active")
      // set state value to user selection
      this.setState({
        methodValue: termChoice
      })

      if (termChoice === "All") {
        if (domainValue === "All") {
          // if both are set to "All," reset the posts
          this.setState({
            projects: [...items],
            domains: getTax([...items], "domains"),
            methods: getTax([...items], "methods"),
          })
        } else {
          // if method is "All" but domain is not "All," filter by domain choice
          const posts = items.filter(node => {
            return node.policyDomains.some(domain => domain.title === domainValue)
          })
          this.setState({
            projects: posts,
            domains: getTax(posts, "domains"),
            methods: getTax(posts, "methods")
          })
        }
      } else {
        if (domainValue !== "All") {
          // if both are not set to "All," filter by both
          const posts = items.filter(node => {
            return node.methods.some(method => method.title === termChoice) &&
                   node.policyDomains.some(domain => domain.title === domainValue)
          })
          this.setState({
            projects: posts,
            domains: getTax(posts, "domains"),
            methods: getTax(posts, "methods")
          })
        } else {
          // if method is not "All," but domain is, filter by method choice
          const posts = items.filter(node => {
            return node.methods.some(method => method.title === termChoice)
          })
          this.setState({
            projects: posts,
            domains: getTax(posts, "domains")
          })
        }
      }
    }
  }

  handleReset = event => {
    const { items } = this.state;
    let allDropdownItems = Array.from(event.target.parentElement.parentElement.querySelectorAll(".dropdown-item"))
    let bothTaxAll = Array.from(event.target.parentElement.parentElement.querySelectorAll(".dropdown-item[data-value*='All']"))

    if (event.target.dataset.value === "Reset") {
      allDropdownItems.forEach(item => {
        // remove active class from all buttons in this list
        item.classList.remove("active")
      })
      // add active class to both "all" items
      bothTaxAll.forEach(item => {
        item.classList.add("active")
      })
      this.setState({
        projects: [...items],
        domains: getTax([...items], "domains"),
        methods: getTax([...items], "methods"),
        domainValue: "All",
        methodValue: "All",
      })
    }
  }
  
  render() {
    const { items, projects, domains, methods, domainValue, methodValue } = this.state
    return (
      <>
        <div className="col-12 col-lg-3 no-gutters u__vspacing projects__filters-wrapper">
          <div className="col-12 projects__filters-wrapper-title">
            <h2>Filter Projects</h2> by:
            <button className="filters-reset btn btn-sm btn-info" type="button" data-value="Reset" onClick={this.handleReset}>Clear filters</button>
          </div>
          <div className="dropdown projects__filter">
            <span className="projects__filter-label" id="policyDomainsDropdown">Policy Domain</span>
            <button className="toggler" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{domainValue}</button>
            <div className="dropdown-menu" data-value="Policy Domain" aria-labelledby="policyDomainsDropdown">
              <button className="dropdown-item active" type="button" data-value="All" onClick={this.handleTaxChange}>All <span className="sr-only">Policy Domains</span></button>
              {domains.map((domain) => {
                return (
                  <button className="dropdown-item" data-value={domain.title} key={domain.contentful_id} onClick={this.handleTaxChange}>{domain.title}</button>
                )
              })}
            </div>
          </div>
          <div className="dropdown projects__filter">
            <span className="projects__filter-label" id="methodsDropdown">Method</span>
            <button className="toggler" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{methodValue}</button>
            <div className="dropdown-menu" data-value="Method" aria-labelledby="methodsDropdown">
              <button className="dropdown-item active" type="button" data-value="All" onClick={this.handleTaxChange}>All <span className="sr-only">Methods</span></button>
              {methods.map((method) => {
                return (
                  <button className="dropdown-item" data-value={method.title} key={method.contentful_id} onClick={this.handleTaxChange}>{method.title}</button>
                )
              })}
            </div>
          </div>
        </div>
        <div className="row col-12 col-lg-9 u__vspacing project__recirc__posts" aria-live="polite">
          <div className="col-12">{`${projects.length} shown of ${items.length}`}</div>
          {projects.map((node) => {
            const featuredImage = getImage(node.featuredImage)
            return (
              <div className="col-sm-6 project__recirc__post d-flex flex-column" key={node.contentful_id}>
                {featuredImage ? (
                  <GatsbyImage
                    image={featuredImage}
                    alt=""
                    className="project__recirc__post__image"
                  />
                ) : (
                  <StaticImage
                    src="../images/yogi-default.png"
                    className="project__recirc__post__image"
                    alt=""
                    layout="fixed"
                    width={275}
                  />
                )}
                <h3 className="project__recirc__post__title h4">
                  <Link to={`/projects/${node.slug}`}>{node.title}</Link>
                </h3>
                <span className="project__recirc__post__meta">
                  {node.methods.map(method => method.title).join(", ")}
                </span>
              </div>
            )
          })}
        </div>
      </>
    )
  }
}

export default ProjectFilters
