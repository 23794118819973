import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import Layout from "../components/layout/Layout"
import ProjectFilters from "../components/ProjectFilters"
import GetUpdates from "../components/GetUpdates"

const ProjectsPage = ({ data }) => {
  return (
    <>
      <Seo title="Projects" bodyClass="page__projects presentation" />
      <Layout>
        <section className="layout__main s__white projects">
          <div className="nav__spacer"></div>
          <div className="s__primary project__recirc">
            <div className="u__container u__spacing">
              <div className="row u__vspacing">
                <div className="col-12 col-lg-9 offset-lg-3 projects__page-title-wrapper">
                  <h1 className="mb-2 projects__page-title">Projects</h1>
                  <span className="lead">
                    Learn more about our work across state, local, and national partners
                  </span>
                </div>
              </div>
              <div className="row">
                <ProjectFilters items={data} />
              </div>
            </div>
          </div>
        </section>
        <GetUpdates sectionClass="s__light" />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    allContentfulProject {
      nodes {
        contentful_id
        featuredImage {
          ... on ContentfulAsset {
            gatsbyImageData(
              layout: FIXED
              aspectRatio: 1.33
              width: 275
            )
          }
        }
        policyDomains {
          title
          contentful_id
          slug
        }
        methods {
          title
          contentful_id
          slug
        }
        slug
        title
      }
    }
    allContentfulPolicyDomain(sort: {fields: title}) {
      nodes {
        contentful_id
        title
        slug
      }
    }
    allContentfulMethod(sort: {fields: title}) {
      nodes {
        contentful_id
        title
        slug
      }
    }
  }
`

export default ProjectsPage
